import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { Fade } from "react-slideshow-image";
import { SliderData } from "../assets/where_config";


const Where = () => {
  const [slides, setSlides] = useState([])

  useEffect(() => {
    (async () => {
      let slides = await shuffle(SliderData)
      setSlides(slides)
    })()
  },[]);

  const shuffle = async (sourceArray) =>  {
    for (var i = 0; i < sourceArray.length - 1; i++) {
      var j = i + Math.floor(Math.random() * (sourceArray.length - i));

      var temp = sourceArray[j];
      sourceArray[j] = sourceArray[i];
      sourceArray[i] = temp;
  }
    return sourceArray;
  }

  return (
    <>

    <Helmet>
        <title>FilmFundi | Where</title>
    </Helmet>

    <div className="page-wrapper">
      <div className="page-inner">
        <div className="slide-container">
          <Fade pauseOnHover={false} canSwipe={false} duration={5000} arrows={false} transitionDuration={2000}>
              {slides && (slides.map((slide, i) =>
                    <div className="each-fade"key={`${i}_where`} interval={slide.interval}>
                      <div className="slider_background_pages" style={{ backgroundImage: `url(${slide.image})`, backgroundPosition: slide.position }}></div>
                    </div> 
                )
              )}
          </Fade> 
        </div>  
      </div>
      <div className="page-inner">
        <div className="content-wrapper">
          <h1>Where</h1>
          <p>We are based in Cape Town, South Africa.</p>
          <p>(If you’re one of the very few who doesn’t already know, it’s a premier international service destination for good reason, offering a huge range of locations, Talent looks and abilities, and first-class Technical, Creative and Production crew. And it’s also just a beautiful city to visit.)</p>
          <p>We offer the same Production Service in the other major South African cities Johannesburg and Durban, and everywhere else in this beautiful and diverse country.</p>
          <p>We also provide Production Service beyond our borders, into the broader Southern African region (e.g. Namibia, Botswana, Mozambique) further afield in Africa (e.g. Tanzania, Kenya, Nigeria, Ghana,) and Indian Ocean islands (e.g. Mauritius.)</p>
        </div>
      </div>
    </div>
    </>
  );

};

export default Where;
