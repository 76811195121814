import React, {useEffect, useState} from "react";
import { Router, Route, Switch } from "react-router-dom";

import NavBar from "./components/NavBar";

import Home from "./views/Home";
import Who from "./views/Who";
import What from "./views/What"
import Where from "./views/Where"
import With from "./views/With"
import Contact from "./views/Contact"

import history from "./utils/history";

// styles
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-slideshow-image/dist/styles.css'

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();


const App = () => {
  const [pageClass, setPageClass] = useState('home');
  const [pageOrientation, setPageOrientation] = useState('portrait');

    useEffect(() => {
      console.log(window)
      return window.addEventListener('orientationchange', doOnOrientationChange);
   },[window]) 

  const doOnOrientationChange = () => {
    console.log('here')
    switch(window.screen.orientation.angle) {  
      case -90:
        setPageOrientation('landscape')
        break; 
      case 90:
        setPageOrientation('landscape')
        break; 
      default:
        setPageOrientation('portrait')
        break; 
    }
  }

  useEffect(() => {
    return history.listen((location) => { 
       console.log(`You changed the page to: ${location.pathname}`) 
       if(location.pathname == '/') {
        setPageClass('home');
       } else {
        setPageClass('other');
       }
    }) 
 },[history]) 

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100 body-wrapper">
        <NavBar/>
          <div id={pageClass} className={`page-container ${pageOrientation}`}>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/who" exact component={Who} />
              <Route path="/what" component={What} />
              <Route path="/where" component={Where} />
              <Route path="/with" component={With} />
              <Route path="/contact" component={Contact} />
            </Switch>
          </div>
      </div>
    </Router>
  );
};

export default App;
