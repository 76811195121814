import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { Fade } from "react-slideshow-image";

import { SliderData } from "../assets/with_config";
import pan_img from "../assets/logos/logo-pan.png" 


const With = () => {
  
  const [slides, setSlides] = useState([])

  useEffect(() => {
    (async () => {
      let slides = await shuffle(SliderData)
      setSlides(slides)
    })()
  },[]);

  const shuffle = async (sourceArray) =>  {
    for (var i = 0; i < sourceArray.length - 1; i++) {
      var j = i + Math.floor(Math.random() * (sourceArray.length - i));

      var temp = sourceArray[j];
      sourceArray[j] = sourceArray[i];
      sourceArray[i] = temp;
  }
    return sourceArray;
  }

  return (

    <>
    <Helmet>
        <title>FilmFundi | With</title>
    </Helmet>

    <div className="page-wrapper">
      <div className="page-inner">
        <div className="slide-container">
          <Fade pauseOnHover={false} canSwipe={false} duration={5000} arrows={false} transitionDuration={2000}>
              {slides && (slides.map((slide, i) =>
                    <div className="each-fade"key={`${i}_with`} interval={slide.interval}>
                      <div className="slider_background_pages" style={{ backgroundImage: `url(${slide.image})`, backgroundPosition: slide.position }}></div>
                    </div> 
                )
              )}
          </Fade> 
        </div>  
      </div>
      <div className="page-inner">
        <div className="content-wrapper">
          <h1>With</h1>
          <p>I have Produced commercials with a stellar array of Producers, Directors, Agencies and Clients from around the world. For further information, contact me.</p>
          <p>Here are just some of the many brands:</p>
          <div className="pan-container">
            <div className="sliding-background" style={{ backgroundImage: `url(${pan_img})`}}></div>
          </div>
        </div>

      </div>
    </div>
    </>
  );

};

export default With;
