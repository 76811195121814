import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { Fade } from "react-slideshow-image";

import { SliderData } from "../assets/home_config";

const Home = () => {
  const [slides, setSlides] = useState([])

  useEffect(() => {
    (async () => {
      let slides = await shuffle(SliderData)
      setSlides(slides)
    })()
  },[]);

  const shuffle = async (sourceArray) =>  {
    for (var i = 0; i < sourceArray.length - 1; i++) {
      var j = i + Math.floor(Math.random() * (sourceArray.length - i));

      var temp = sourceArray[j];
      sourceArray[j] = sourceArray[i];
      sourceArray[i] = temp;
  }
    return sourceArray;
  }

  
  return (
    <>
      <Helmet>
          <title>FilmFundi | Home</title>
      </Helmet>
      <div className="slide-container">
        <Fade pauseOnHover={false} canSwipe={false} duration={7000} arrows={false} transitionDuration={2000}>
            {slides && (slides.map((slide, i) =>
                  <div className="each-fade"key={`${i}_home`} interval={slide.interval}>
                    <div className="slider_background_home" style={{ backgroundImage: `url(${slide.image})`, backgroundPosition: slide.position, backgroundPositionY: slide.position_y ? slide.position_y : 'defualt'  }}></div>
                  </div> 
              )
            )}
        </Fade> 
      </div>
    </>

  );
};

export default Home;
