import React, {useState, useEffect} from "react";
import { NavItem, NavLink} from "reactstrap";
import Modal from 'react-bootstrap/Modal'
import {NavLink as RouterNavLink} from "react-router-dom";
import { useHistory } from 'react-router-dom'

import logo from '../assets/logo.png'

const NavBar = () => {

const history = useHistory() 
const [show, setShow] = useState(false);
const [logoLocationStyle, setLogoLocationStyle] = useState('logo_home')

useEffect(() => {
    return history.listen((location) => { 
       console.log(`You changed the page to: ${location.pathname}`) 
       if(location.pathname == '/') {
        setLogoLocationStyle('logo_home');
       } else {
        setLogoLocationStyle('logo_other');
       }
    }) 
 },[history]) 

const handleShow = async () => {
    setShow(true);
}

  return (
    <div className="nav-container">
        
        <NavLink tag={RouterNavLink} to="/" exact >
            <img className={logoLocationStyle} src={logo}/>
        </NavLink>
    
      <nav className="large-nav">
        <ul className="navbar-nav main-menu ml-auto">
          <NavItem>
              <NavLink
                  tag={RouterNavLink}
                  to="/who"
                  exact
                  activeClassName="router-link-exact-active"
              >
                  who
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink
                  tag={RouterNavLink}
                  to="/what"
                  exact
                  activeClassName="router-link-exact-active"
              >
                  what
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink
                  tag={RouterNavLink}
                  to="/where"
                  exact
                  activeClassName="router-link-exact-active"
              >
                  where
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink
                  tag={RouterNavLink}
                  to="/with"
                  exact
                  activeClassName="router-link-exact-active"
              >
                  with
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink
                  tag={RouterNavLink}
                  to="/contact"
                  exact
                  activeClassName="router-link-exact-active"
              >
                  contact
              </NavLink>
          </NavItem>
        </ul>
      </nav>

      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
            <img className="logo-header" src={logo}/>
        </Modal.Header>
        <Modal.Body>
        <NavItem>
            <NavLink
                  tag={RouterNavLink}
                  to="/"
                  exact
                  activeClassName="router-link-exact-active"
                  onClick={() => setShow(false)}
              >
                  home
              </NavLink>
              <NavLink
                  tag={RouterNavLink}
                  to="/who"
                  exact
                  activeClassName="router-link-exact-active"
                  onClick={() => setShow(false)}
              >
                  who
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink
                  tag={RouterNavLink}
                  to="/what"
                  exact
                  activeClassName="router-link-exact-active"
                  onClick={() => setShow(false)}
              >
                  what
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink
                  tag={RouterNavLink}
                  to="/where"
                  exact
                  activeClassName="router-link-exact-active"
                  onClick={() => setShow(false)}
              >
                  where
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink
                  tag={RouterNavLink}
                  to="/with"
                  exact
                  activeClassName="router-link-exact-active"
                  onClick={() => setShow(false)}
              >
                  with
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink
                  tag={RouterNavLink}
                  to="/contact"
                  exact
                  activeClassName="router-link-exact-active"
                  onClick={() => setShow(false)}
              >
                  contact
              </NavLink>
          </NavItem>
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default NavBar;
