import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { Fade } from "react-slideshow-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SliderData } from "../assets/what_config";
import audio_url from '../assets/fundi_pronunciation.mp3'

const What = () => {
  const [slides, setSlides] = useState([])
  const [audio] = useState(new Audio(audio_url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    (async () => {
      let slides = await shuffle(SliderData)
      setSlides(slides)
    })()
  },[]);

  const shuffle = async (sourceArray) =>  {
    for (var i = 0; i < sourceArray.length - 1; i++) {
      var j = i + Math.floor(Math.random() * (sourceArray.length - i));

      var temp = sourceArray[j];
      sourceArray[j] = sourceArray[i];
      sourceArray[i] = temp;
  }
    return sourceArray;
  }

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  },
  [playing, audio]
);

useEffect(() => {
  audio.addEventListener('ended', () => setPlaying(false));
  return () => {
    audio.removeEventListener('ended', () => setPlaying(false));
  };
}, [audio]);



  return (
  <>
    <Helmet>
        <title>FilmFundi | What</title>
    </Helmet>
    
    <div className="page-wrapper">
      <div className="page-inner">
        <div className="slide-container">
          <Fade pauseOnHover={false} canSwipe={false} duration={5000} arrows={false} transitionDuration={2000}>
              {slides && (slides.map((slide, i) =>
                    <div className="each-fade"key={`${i}_what`} interval={slide.interval}>
                      <div className="slider_background_pages" style={{ backgroundImage: `url(${slide.image})`, backgroundPosition: slide.position }}></div>
                    </div> 
                )
              )}
          </Fade> 
        </div>
      </div>
      <div className="page-inner">
        <div className="content-wrapper">
          <h1>What</h1>
          <p>FilmFundi is a Film Service Production Company focused primarily (but not exclusively) on Commercial projects.</p>
          <p>We provide full production service: thorough budgeting and planning in support of your pitch, management of absolutely everything your Production requires “on the ground,” and detailed wrap/budgetary reconciliation.</p>
          <p>Ours is a responsive and committed collaborative approach, seeking to fully understand and support the specific needs of your project, helping you to realise its full potential.</p>
          <p>We’re all about world-class production, rising to any challenge, and enjoying it all along the way.</p>
          <p>So… what is a Fundi? <span className="audio-btn" onClick={toggle}><FontAwesomeIcon icon="volume-up" className="mr-2" /></span></p>
          <p>In short, a Fundi is an expert, a person who is highly knowledgeable or skilled in their field. It derives from the word “funda” (in isiXhosa) which means “learn.”</p>
          <p>And that sort of sums it up – we’re big on expertise, but we’re also always learning. We exist in the space between having seen-it-all, and knowing that there’s something new around every corner.</p>
        </div>
      </div>
    </div>
    </>
  );

};

export default What;
