import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { Fade } from "react-slideshow-image";
import { SliderData } from "../assets/who_config";

const Who = () => {
  const [slides, setSlides] = useState([])

  useEffect(() => {
    (async () => {
      let slides = await shuffle(SliderData)
      setSlides(slides)
    })()
  },[]);

  const shuffle = async (sourceArray) =>  {
    for (var i = 0; i < sourceArray.length - 1; i++) {
      var j = i + Math.floor(Math.random() * (sourceArray.length - i));

      var temp = sourceArray[j];
      sourceArray[j] = sourceArray[i];
      sourceArray[i] = temp;
  }
    return sourceArray;
  }

  return (
    <>
    <Helmet>
        <title>FilmFundi | Who</title>
    </Helmet>

    <div className="page-wrapper">
      <div className="page-inner">
        <div className="slide-container">
          <Fade pauseOnHover={false} canSwipe={false} duration={5000} arrows={false} transitionDuration={2000}>
              {slides && (slides.map((slide, i) =>
                    <div className="each-fade"key={`${i}_who`} interval={slide.interval}>
                      <div className="slider_background_pages" style={{ backgroundImage: `url(${slide.image})`, backgroundPosition: slide.position }}></div>
                    </div> 
                )
              )}
          </Fade> 
        </div>  
      </div>
      <div className="page-inner">
        <div className="content-wrapper">
          <h1>Who</h1>
          <p>Torben Bülbring, that’s who.</p>
          <p>Film Industry “lifer” and veteran of the South African production service scene.</p>
          <p>Over two decades producing commercials with Clients from all over the world, from the smallest of docu-style projects to the largest of all-singing, all-dancing spectaculars.</p>
          <p>A Producer who brings sharp analysis, deep insight into both the creative and commercial processes, and unwavering precision to every project.</p>
          <p>A Producer who is aware, people-centred, and calm. Very calm.</p>
          <p>A Producer who loves the filmmaking process, the circus, the magic.</p>
          <p>And a Producer with the deft touch that makes for great service.</p>
        </div>
      </div>
    </div>
    </>
  );

};

export default Who;
