import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { Fade } from "react-slideshow-image";

import { SliderData } from "../assets/contact_config";

const Contact = () => {
  const [slides, setSlides] = useState([])

  useEffect(() => {
    (async () => {
      let slides = await shuffle(SliderData)
      setSlides(slides)
    })()
  },[]);

  const shuffle = async (sourceArray) =>  {
    for (var i = 0; i < sourceArray.length - 1; i++) {
      var j = i + Math.floor(Math.random() * (sourceArray.length - i));

      var temp = sourceArray[j];
      sourceArray[j] = sourceArray[i];
      sourceArray[i] = temp;
  }
    return sourceArray;
  }

  return (
    <>
    <Helmet>
        <title>FilmFundi | Contact</title>
    </Helmet>
    <div className="page-wrapper">
      <div className="page-inner">
        <div className="slide-container">
          <Fade pauseOnHover={false} canSwipe={false} duration={5000} arrows={false} transitionDuration={2000}>
              {slides && (slides.map((slide, i) =>
                    <div className="each-fade"key={`${i}_contact`} interval={slide.interval}>
                      <div className="slider_background_pages" style={{ backgroundImage: `url(${slide.image})`, backgroundPosition: slide.position }}></div>
                    </div> 
                )
              )}
          </Fade> 
        </div>
      </div>
      <div className="page-inner">
        <div className="content-wrapper">
          <h1>Contact</h1>
          <a href="https://goo.gl/maps/AYJ11zPmwVUSQLeS9" target={'blank'}>
            <li>Unit A206, The Old Biscuit Mill</li>
            <li>373-375 Albert Road</li>
            <li>Woodstock</li>
            <li>Cape Town</li>
            <li>South Africa</li>
            <li>7925</li>
          </a>
          <p></p>
          <a href="mailto:info@filmfundi.com">info@filmfundi.com</a>
          <a href="mailto:torben@filmfundi.com">torben@filmfundi.com</a>
          <p></p>
          <a href="tel:+27214218101">Office &ensp;&ensp;+27 21 421 8101</a>
          <a href="tel:+27837777072">Mobile &ensp; +27 83 777 7072</a>
          
        </div>
      </div>
    </div>
    </>
  );

};

export default Contact;
