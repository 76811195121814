
import image_1 from './home/img_1.jpg'
import image_2 from './home/img_2.jpg'
import image_3 from './home/img_3.jpg'
import image_4 from './home/img_4.jpg'
import image_5 from './home/img_5.jpg'
import image_6 from './home/img_6.jpg'
import image_7 from './home/img_7.jpg'
import image_8 from './home/img_8.jpg'
import image_9 from './home/img_9.jpg'
import image_10 from './home/img_10.jpg'
import image_11 from './home/img_11.jpg'
import image_12 from './home/img_12.jpg'
import image_13 from './home/img_13.jpg'
import image_14 from './home/img_14.jpg'
import image_15 from './home/img_15.jpg'
import image_16 from './home/img_16.jpg'
import image_17 from './home/img_17.jpg'
import image_18 from './home/img_18.jpg'
import image_19 from './home/img_19.jpg'
import image_20 from './home/img_20.jpg'
import image_21 from './home/img_21.jpg'
import image_22 from './home/img_22.jpg'
import image_23 from './home/img_23.jpg'
import image_24 from './home/img_24.jpg'
import image_25 from './home/img_25.jpg'
import image_26 from './home/img_26.jpg'
import image_28 from './home/img_28.jpg'
import image_27 from './home/img_27.jpg'
import image_29 from './home/img_29.jpg'
import image_30 from './home/img_30.jpg'
import image_31 from './home/img_31.jpg'
import image_32 from './home/img_32.jpg'
import image_33 from './home/img_33.jpg'
import image_34 from './home/img_34.jpg'
import image_35 from './home/img_35.jpg'
import image_36 from './home/img_36.jpg'
import image_37 from './home/img_37.jpg'
import image_38 from './home/img_38.jpg'
import image_39 from './home/img_39.jpg'
import image_40 from './home/img_40.jpg'
import image_41 from './home/img_41.jpg'
import image_42 from './home/img_42.jpg'

export const SliderData = [
    {
        image: image_1,
        position: 'bottom',
    }, {
        image: image_2,
        position: 'bottom',
    },{
        image: image_3,
        position: 'bottom',
    },{
        image: image_4,
        position: 'bottom',
    }, {
        image: image_5,
        position: 'bottom',
    }, {
        image: image_6,
        position: 'bottom',
    }, {
        image: image_7,
        position: 'bottom',
    }, {
        image: image_8,
        position: 'bottom',
    },{
        image: image_9,
        position: 'bottom',
    },{
        image: image_10,
        position: 'bottom',
    }, {
        image: image_11,
        position: 'bottom',
    }, {
        image: image_12,
        position: 'bottom',
    }, {
        image: image_13,
        position: 'bottom',
    }, {
        image: image_14,
        position: 'bottom',
    }, {
        image: image_15,
        position: 'bottom',
    }, {
        image: image_16,
        position: 'bottom',
    }, {
        image: image_17,
        position: 'bottom',
    }, {
        image: image_18,
        position: 'bottom',
    }, {
        image: image_19,
        position: 'bottom',
    }, {
        image: image_20,
        position: 'bottom',
    }, {
        image: image_21,
        position: 'bottom',
    }, {
        image: image_22,
        position: 'bottom',
    },
    {
        image: image_23,
        position: 'bottom',
    }, {
        image: image_24,
        position: 'bottom',
    }, {
        image: image_25,
        position: 'bottom',
    },{
        image: image_26,
        position: 'bottom',
    },
    {
        image: image_27,
        position: 'bottom',
    }, {
        image: image_28,
        position: 'bottom',
    }, {
        image: image_29,
        position: 'bottom',
    }, {
        image: image_30,
        position: 'bottom',
    }, {
        image: image_31,
        position: 'bottom',
    }, {
        image: image_32,
        position: 'bottom',
    }, {
        image: image_33,
        position: 'bottom',
    }, {
        image: image_34,
        position: 'bottom',
    }, {
        image: image_35,
        position: 'bottom',
    }, {
        image: image_36,
        position: 'bottom',
    }, {
        image: image_37,
        position: 'bottom',
    }, {
        image: image_38,
        position: 'bottom',
    }, {
        image: image_39,
        position: 'bottom',
    }, {
        image: image_40,
        position: 'bottom',
    }, {
        image: image_41,
        position: 'bottom',
    }, {
        image: image_42,
        position: 'bottom',
    }
  ];
  