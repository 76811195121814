
import image_1 from './who/img_1.jpg'
import image_2 from './who/img_2.jpg'
import image_3 from './who/img_3.jpg'
import image_4 from './who/img_4.jpg'
import image_5 from './who/img_5.jpg'
import image_6 from './who/img_6.jpg'
import image_7 from './who/img_7.jpg'
import image_8 from './who/img_8.jpg'
import image_9 from './who/img_9.jpg'


export const SliderData = [
    {
        image: image_1,
        position: 'center',
        interval: 10000
    }, {
        image: image_2,
        position: 'top',
        interval: 10000
    }, {
        image: image_3,
        position: 'center',
        interval: 10000
    }, {
        image: image_4,
        position: 'center',
        interval: 10000
    }, {
        image: image_5,
        position: 'center',
        interval: 10000
    }, {
        image: image_6,
        position: 'center',
        interval: 10000
    }, {
        image: image_7,
        position: 'center',
        interval: 10000
    }, {
        image: image_8,
        position: 'center',
        interval: 10000
    }, {
        image: image_9,
        position: 'center',
        interval: 10000
    }
  ];
  